<template>
  <div>
    <!-- Filters -->
    <!-- <app-collapse hover>
      <app-collapse-item title="Filters">
        <ApiKeyListFilters
          :status-filter.sync="statusFilter"
          :status-options="apiKeyStatusOption"
        />
      </app-collapse-item>
    </app-collapse> -->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="apiKeysMetaData.length && $can('api_key_delete')"
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div
              v-if="selectedApiKeys.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Name"
                />
              </b-input-group>

              <b-button
                v-if="$can('api_key_create')"
                variant="outline-info"
                v-b-modal.modal-add-record
                class="text-nowrap"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Generate API Key</span>
              </b-button>
            </div>
            <b-button
              v-else-if="$can('api_key_delete')"
              class="float-right"
              variant="danger"
              :disabled="apiKeysDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ apiKeysDeleteCount }} Api Key</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedApiKeys"
        name="selectedApiKeys"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchApiKeyList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap text-center">
              <b-form-checkbox :value="data.item.hashid" class="mr-0">
              </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('api_key_show')"
                :to="{
                  name: 'view-api-key',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
              >
                {{ data.item.title }}
              </b-link>
              <span v-else>
                {{ data.item.title }}
              </span>
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status == "1" ? "Active" : "In Active" }}
            </b-badge>
          </template>

          <!--Column: Status -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTime }}
          </template>
          <!--Column: Status -->
          <template #cell(key)="data">
            *********

            <b-button
              type="button"
              v-clipboard:copy="data.item.key"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              variant="secondary"
              size="sm"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <!-- <b-dropdown-item
                class="btn-sm p-0"
                @click="editRecord(data)"
                v-if="$can('api_key_edit')"
              >
                <feather-icon icon="EditIcon" size="13" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item> -->
              <!-- 
              <b-dropdown-item
                class="btn-sm p-0"
                @click="viewRecord(data)"
                v-if="$can('api_key_show')"
              >
                <feather-icon icon="EyeIcon" size="13" class="mr-50" />
                <span>View</span>
              </b-dropdown-item> -->

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('api_key_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedApiKeys.length == 0"
              v-model="currentPage"
              :total-rows="totalApiKeyList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('api_key_delete')"
              class="float-right"
              variant="danger"
              :disabled="apiKeysDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ apiKeysDeleteCount }} Api Key</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card class="mt-1" title="Available APIs" v-if="apis">
      <app-collapse accordion type="shadow">
        <app-collapse-item v-for="api in apis" :title="api.name">
          <ul>
            <li>
              API Name : <b> {{ api.name }} </b>
            </li>
            <li>
              API Description : <b> {{ api.description }} </b>
            </li>
            <li>
              API Method : <b> {{ api.method }} </b>
            </li>
            <li>
              API Endpoint : <b> {{ host + api.endpoint }} </b>
            </li>

            <li>
              API Request Headers :
              <b>
                <ul>
                  <li v-for="(value, key) in api.headers">
                    {{ key }} : {{ value }}
                  </li>
                </ul></b
              >
            </li>
            <li>
              API Request Params :
              <b>
                <ul>
                  <li v-for="(value, key) in api.params">
                    {{ key }} : {{ value }}
                  </li>
                </ul></b
              >
            </li>
            <li>
              API Request Body : <b> {{ api.body }}</b>
            </li>
            <li>
              API Request Response :<b> {{ api.response }}</b>
            </li>
          </ul>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <!-- Modal: add Record -->

    <b-modal id="modal-add-record" title="API Key" centered :hide-footer="true">
      <div
        v-if="$can('employee_create')"
        class="d-flex ml-2 align-items-center"
      >
        <b-form autocomplete="off" id="bulk-import" name="bulk-import" style="width: 100%">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label class="required-l" for="name">Name</label>
              <b-form-input
                id="name"
                v-model="data_local.name"
                name="name"
                :state="errors.length > 0 ? false : null"
                placeholder="API Name"
                class="text-lowercase"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <div class="text-center w-100 mt-1">
            <button
              type="button"
              class="btn btn-success"
              @click="create"
              v-if="data_local.name.length > 0 && data_local.name"
            >
              Generate
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, numeric } from "@validations";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import apiKeyStoreModule from "../apiKeyStoreModule";
import useApiKeyList from "./useApiKeyList";
import ApiKeyListFilters from "./ApiKeyListFilters.vue";
import axios from "@axios";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ApiKeyListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-api-keys";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedApiKeys = ref([]);
    const apiKeysDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME, apiKeyStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchApiKeyList,
      tableColumns,
      perPage,
      currentPage,
      totalApiKeyList,
      apiKeysMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByApiKeyUrgencyStatus,
      filterByApiKeyType,
      resolveUserStatusVariant,
      apiKeyStatusOption,
    } = useApiKeyList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedApiKeys.value = [];
      if (val) {
        apiKeysMetaData.value.forEach((api_key) => {
          selectedApiKeys.value.push(api_key.hashid);
        });
      }
    });

    watch(selectedApiKeys, (val) => {
      apiKeysDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchApiKeyList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalApiKeyList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByApiKeyUrgencyStatus,
      filterByApiKeyType,
      selectedApiKeys,
      apiKeysDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      apiKeyStatusOption,
      apiKeysMetaData,
    };
  },
  data() {
    return {
      is_loading: {
        submit: false,
      },
      data_local: {
        name: "",
      },
      formData: {
        url: "api-keys",
      },
      apis: [],
      host: process.env.VUE_APP_API_URL,
    };
  },
  created() {
    axios
      .get("api-keys/api_list")
      .then((response) => {
        if (response.data.success) {
          this.apis = response.data.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "API List Retrived",
              icon: "EditIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to API List",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch((error) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Unable to API List",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Please try again after sometime",
          },
        });
      });
    if (!this.$can("api_key_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
  },
  methods: {
    onCopy: function (e) {
      this.$toast.success("You just copied key to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the text to the clipboard");
      console.log(e);
    },
    create() {
      const self = this;
      self.is_loading.submit = true;
      self.formData.params = self.data_local;

      this.$store
        .dispatch("app/store", self.formData)
        .then((res) => {
          // Add apiGenerateKey
          self.is_loading.submit = false;
          this.refetchData();
          this.$bvModal.hide("modal-add-record");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Generated API Key.",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.is_loading.submit = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! API Key Creation Failed.",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    editRecord(data) {
      this.$router.push(`/edit/api-key/${data.item.hashid}`).catch(() => {});
    },
    viewRecord(data) {
      this.$router.push(`/view/api-key/${data.item.hashid}`).catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedApiKeys.length} Api Key, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-api-keys/removeMultipleApiKeys",
              this.selectedApiKeys
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedApiKeys = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully.",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Api Key Deletion failed.",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchApiKeysData() {
      console.log("Added Api Key");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-api-keys/removeApiKey", id)
        .then((res) => {
          if (res.status == "204") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Api Key Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Api Key Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Api Key Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Api Key Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
